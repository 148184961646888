import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },

  //penilaian area
  {
    path: "/penilaianabsensi",
    name: "Absensi",
    component: () => import("../views/PenilaianAbsensi"),
  },
  {
    path: "/penilaianriwayatabsensi",
    name: "Riwayat Absensi",
    component: () => import("../views/PenilaianRiwayatAbsensi"),
  },
  {
    path: "/penilaianinputnilai",
    name: "Input Nilai",
    component: () => import("../views/PenilaianInputNilai"),
  },
  {
    path: "/penilaianinputnilaimobile",
    name: "Input Nilai Mobile",
    component: () => import("../views/PenilaianInputNilaiMobile"),
  },
  {
    path: "/penilaianinputsikap",
    name: "Input Nilai Sikap",
    component: () => import("../views/PenilaianInputSikap"),
  },
  {
    path: "/penilaiankompetensidasar",
    name: "Input Kompetensi Dasar",
    component: () => import("../views/PenilaianKompetensiDasar"),
  },
  {
    path: "/penilaianinputprestasi/:id_kelas/:id_siswa/:id_prestasi",
    name: "Input Prestasi Siswa",
    component: () => import("../views/PenilaianInputPrestasi"),
  },
  {
    path: "/penilaianlistprestasi/:id_kelas/:id_siswa",
    name: "List Prestasi Siswa",
    component: () => import("../views/PenilaianListPrestasi"),
  },
  {
    path: "/penilaianinputcatatan",
    name: "Input Catatan",
    component: () => import("../views/PenilaianInputCatatan"),
  },
  {
    path: "/penilaianremedial",
    name: "Remedial",
    component: () => import("../views/PenilaianRemedial"),
  },
  {
    path: "/penilaianinputekskul",
    name: "Input Ekskul",
    component: () => import("../views/PenilaianInputEkskul"),
  },
  {
    path: "/penilaianinputpembinaan/:id",
    name: "Input Pembinaan",
    component: () => import("../views/PenilaianInputPembinaan"),
  },
  //end penilaian

  //hasil area
  {
    path: "/hasilevaluasikehadiran",
    name: "Evaluasi Kehadiran",
    component: () => import("../views/HasilEvaluasiKehadiran"),
  },
  {
    path: "/hasilRanking",
    name: "Ranking",
    component: () => import("../views/HasilRanking"),
  },
  {
    path: "/hasilrekapnilai",
    name: "Rekapitulasi Penilaian",
    component: () => import("../views/HasilRekapNilai"),
  },
  {
    path: "/hasilrekappembinaan/:id",
    name: "Rekapitulasi Pembinaan",
    component: () => import("../views/HasilRekapPembinaan"),
  },
  {
    path: "/hasilraportdigital",
    name: "Raport Digital",
    component: () => import("../views/HasilRaportDigital"),
  },
  {
    path: "/hasilraportmid",
    name: "Raport Mid Semester",
    component: () => import("../views/HasilRaportMid"),
  },
  {
    path: "/hasilsampul",
    name: "Sampul",
    component: () => import("../views/HasilSampul"),
  },
  //end hasil

  //pengaturan area
  {
    path: "/pengaturaneditakun",
    name: "Edit Akun",
    component: () => import("../views/PengaturanEditAkun"),
  },
  {
    path: "/pengaturaneditpassword",
    name: "Edit Password",
    component: () => import("../views/PengaturanEditPassword"),
  },
  {
    path: "/pengaturanmapel",
    name: "Mata Pelajaran",
    component: () => import("../views/PengaturanMapel"),
  },
  {
    path: "/pengaturankompetensimapel",
    name: "Kompetensi Dasar Mapel",
    component: () => import("../views/PengaturanKompetensiMapel"),
  },
  {
    path: "/pengaturanrombel",
    name: "Rombongan Belajar",
    component: () => import("../views/PengaturanRombel"),
  },
  {
    path: "/pengaturanekskul",
    name: "Ekskul",
    component: () => import("../views/PengaturanEkskul"),
  },
  {
    path: "/pengaturanisiekskul/:id/:nama",
    name: "Isi Ekskul",
    component: () => import("../views/PengaturanIsiEkskul"),
  },
  {
    path: "/pengaturangurumapel",
    name: "Guru Mata Pelajaran",
    component: () => import("../views/PengaturanGuruMapel"),
  },
  {
    path: "/pengaturangurumapelbaru",
    name: "Guru Mata Pelajaran Baru",
    component: () => import("../views/PengaturanGuruMapelBaru"),
  },
  //end pengaturan
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  var toLogin = to.name == "Login";
  var isUser = localStorage.getItem("token") != null;
  var toHome = to.name == "Home";

  // hide header if not login page
  if (toLogin) {
    Vue.prototype.$isLogin = true;
  } else {
    Vue.prototype.$isLogin = false;
  }

  if (toHome) {
    Vue.prototype.$isHome = true;
  } else {
    Vue.prototype.$isHome = false;
  }

  // router guards
  if (!toLogin && !isUser) {
    next("/login");
  } else if (!toLogin && isUser) {
    next();
  } else if (toLogin && isUser) {
    next("/");
  } else if (toLogin && !isUser) {
    next();
  }
});

export default router;
