import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    is_kepsek: false,
    is_walas: false,
    is_mapel: false,
    is_bk: false,
    is_bk: false,
    is_kurikulum: false,
    is_ekskul: false,
    is_super_user: false,
    user: {},
  },
  mutations: {
    setRole(state, data) {
      state.is_kepsek = data.is_kepsek;
      state.is_walas = data.is_walas;
      state.is_mapel = data.is_mapel;
      state.is_bk = data.is_bk;
      state.is_ekskul = data.is_ekskul;
      state.is_kurikulum = data.is_kurikulum;
      state.is_super_user = data.is_super_user;
    },
    setCurrentUser(state, data) {
      state.user = data;
      // console.log(state.user);
    },
    clearState(state) {
      state = {
        is_kepsek: false,
        is_walas: false,
        is_mapel: false,
        is_bk: false,
        is_bk: false,
        is_kurikulum: false,
        is_super_user: false,
        user: {},
      };
      // console.log(state);
    },
  },
  actions: {
    async sendCurrentUser(state) {
      var header = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      let resp = await Vue.prototype.$http.get("/user_data", header);
      state.commit("setCurrentUser", resp.data);
      // console.log(state.user);
    },
  },
  modules: {},
  getters: {
    async getCurrentUser(state) {
      return state.user;
    },
  },
});
