import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";

Vue.config.productionTip = false;

// your custom code
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$http = axios;
Vue.prototype.$isLogin = false;

import helpers from "@/helpers";
Vue.use(helpers);
Vue.use(require("vue-moment"));

Vue.directive("can", {
  bind: (el, binding, vnode) => {
    // vuex diisi saat sukses login
    let store = vnode.context.$store;
    // console.log(store.state);
    // hak akses portal akademik: WALAS, MAPEL, BK, EKSKUL, KEPSEK, ADMIN
    if (binding.value.includes("KEPSEK") && store.state.is_kepsek) {
      return;
    } else if (binding.value.includes("WALAS") && store.state.is_walas) {
      return;
    } else if (binding.value.includes("MAPEL") && store.state.is_mapel) {
      return;
    } else if (binding.value.includes("BK") && store.state.is_bk) {
      return;
    } else if (binding.value.includes("EKSKUL") && store.state.is_ekskul) {
      return;
    } else if (store.state.is_super_user) {
      return;
    } else if (
      binding.value.includes("KURIKULUM") &&
      store.state.is_kurikulum
    ) {
      return;
    } else {
      vnode.elm.style.display = "none";
    }
  },
});
// end of your custom code

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
