<template>
  <q-layout view="hHh lpR fFf">
    <q-header
      bordered
      class="bg-primary text-white"
      v-if="!$isLogin && !isSmallScreen"
    >
      <q-toolbar>
        <q-btn
          v-show="!$isHome"
          dense
          flat
          icon="home"
          @click="backHome"
        ></q-btn>
        <q-img
          src="./img/Logo Perguruan Horizontal.png"
          contain
          class="bg-white"
          :ratio="13 / 6"
          style="height:50px; max-width:100px"
        ></q-img>
        <q-toolbar-title
          >Portal Akademik -> {{ user.instansi }}</q-toolbar-title
        >

        <div class="row justify-between">
          <q-btn-dropdown flat no-caps :label="user.nama">
            <q-list>
              <q-item
                clickable
                v-close-popup
                @click="$router.push('/pengaturaneditakun')"
              >
                <q-item-section>
                  <q-item-label class="text-bold">Edit</q-item-label>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                class="text-negative"
                v-close-popup
                @click="logout"
              >
                <q-item-section>
                  <q-item-label class="text-bold">Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
      <q-bar class="row justify-between">
        <a class="text-overline">{{ $route.name }}</a>
        <a class="text-overline">
          Tahun Ajaran Berlangsung :
          <b>{{ user.tahun_ajaran }}</b>
        </a>
      </q-bar>
    </q-header>

    <q-header
      bordered
      class="bg-primary text-white"
      v-if="!$isLogin && isSmallScreen"
    >
      <q-toolbar>
        <q-btn
          v-show="!$isHome"
          dense
          flat
          icon="home"
          @click="backHome"
        ></q-btn>
        <q-img
          src="./img/Logo Perguruan Horizontal.png"
          contain
          class="bg-white"
          :ratio="13 / 6"
          style="height:50px; max-width:100px"
        ></q-img>
        <q-toolbar-title>Akademik</q-toolbar-title>
        <q-btn flat dense icon="account_circle">
          <q-menu anchor="top left">
            <q-list>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-uppercase">
                    {{ user.nama }}
                  </q-item-label>
                  <q-item-label overline>{{ user.instansi }}</q-item-label>
                  <q-item-label caption>{{ user.tahun_ajaran }}</q-item-label>
                </q-item-section>

                <q-item-section side top class="column justify-evenly">
                  <q-btn
                    flat
                    dense
                    size="sm"
                    color="positive"
                    to="/pengaturaneditakun"
                    >edit</q-btn
                  >
                  <q-btn flat dense size="sm" color="negative" @click="logout"
                    >logout</q-btn
                  >
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-toolbar>

      <q-bar class="row justify-between">
        <a class="text-overline">{{ $route.name }}</a>
      </q-bar>
    </q-header>

    <q-page-container>
      <router-view v-if="isReady" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      isReady: false,
      toLogin: false,
      left: false,
      user: {
        jenjang: "",
        username: "",
        tahun_ajaran: "",
      },
    };
  },
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    isUser() {
      return localStorage.getItem("token") != null;
    },
  },
  async created() {
    if (this.isUser) {
      await this.login();
      this.$forceUpdate();
    } else {
      // if no token, routerview immediately ready
      this.isReady = true;
    }
  },
  watch: {
    $route: async function(to, from) {
      if (to.name == "Home") {
        await this.login();
      }
    },
  },
  methods: {
    ...mapActions(["sendCurrentUser"]),
    ...mapMutations(["setRole", "clearState"]),
    async login() {
      try {
        let resp = await this.$http.get("/user_data", this.$hlp.getHeader());
        resp.data.jenjang = localStorage.getItem("jenjang");
        resp.data.tahun_ajaran = localStorage.getItem("nama_tahun_ajaran");
        resp.data.instansi = "SMP Ar Risalah Putra";
        if (resp.data.jenjang == "SMP PI") {
          resp.data.instansi = "SMP Ar Risalah Putri";
        }
        if (resp.data.jenjang == "MA") {
          resp.data.instansi = "MAS Ar Risalah";
        }
        this.user = resp.data;

        let data = {
          is_bk: this.db_filter(resp.data.is_guru_bk),
          is_mapel: this.db_filter(resp.data.is_guru_mapel),
          is_kepsek: this.db_filter(resp.data.is_kepala_sekolah),
          is_ekskul: this.db_filter(resp.data.is_pembina_ekskul),
          is_walas: this.db_filter(resp.data.is_wali_kelas),
          is_kurikulum: this.db_filter(resp.data.is_kurikulum),
          is_super_user: this.db_filter(resp.data.is_super_user),
        };
        this.setRole(data);
        await this.sendCurrentUser();
        this.isReady = true;
      } catch (err) {
        this.logout(); //check if token valid
      }
    },
    db_filter(x) {
      if (x == "1") {
        return true;
      } else {
        return false;
      }
    },
    backHome() {
      this.$router.push("/");
    },
    logout() {
      localStorage.clear();
      this.$q.notify({
        type: "positive",
        message: "proses logout berhasil",
      });
      this.clearState();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.sass";
.stickyTable {
  height: 300px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $indigo-5;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.stickyRow {
  tr th {
    position: sticky;
    z-index: 2;
    top: 0;
  }
}
.stickyName {
  tbody tr td:nth-child(2) {
    background-color: antiquewhite;
    position: sticky;
    z-index: 1;
    left: 0;
  }
}

.stickyHeaderColumn {
  height: 300px;
  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: antiquewhite !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child tr:nth-child(2) th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }
  thead tr:first-child th {
    top: 0;
    z-index: 1;
    background-color: $indigo-5;
  }
  thead tr:nth-child(2) th {
    top: 28px;
    z-index: 1;
    background-color: $indigo-5;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
